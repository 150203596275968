/* eslint-disable unicorn/no-null */

/* eslint-disable jsdoc/require-jsdoc */

/** Evergreen browsers require this. */

/**
 * Zone JS is required by Angular itself.
 */

/**
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import 'zone.js';

if (process.env.BUILD_ENV !== 'production') {
	// eslint-disable-next-line @typescript-eslint/no-require-imports
	require('zone.js/plugins/zone-error');
}

/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/**
 * BROWSER POLYFILLS
 */

// TODO: Remove as soon as we drop support for Safari <15 and Firefox <94 (ESR).
if (window.newrelic) {
	window.newrelic?.addPageAction?.('hasFeature', {
		structuredClone: 'structuredClone' in window,
	});
}

/**
 * APPLICATION IMPORTS
 */

//#region Node Process

window.process = {
	browser: true,
	title: 'browser',
	nextTick: (callback: () => void) => setTimeout(callback, 0),
	env: {},
	argv: [],
	version: '', // empty string to avoid regexp issue,
	versions: {},
	on: () => {},
	addListener: () => {},
	once: () => {},
	off: () => {},
	removeListener: () => {},
	removeAllListeners: () => {},
	emit: () => {},
	prependListener: () => {},
	prependOnceListener: () => {},
	listeners: (_name: string) => [],
	cwd: () => '/',
	umask: () => 0,
	binding(_name: string) {
		throw new Error('process.binding is not supported');
	},
	chdir(_dir: string) {
		throw new Error('process.chdir is not supported');
	},
} as unknown as NodeJS.Process;

let queue: Item[] = [];
let isDraining = false;
let currentQueue: Item[] | null;
let queueIndex = -1;

class Item {
	fun: (...args: unknown[]) => unknown;
	args: unknown[];
	constructor(fun: (...args: unknown[]) => unknown, args: unknown[]) {
		this.fun = fun;
		this.args = args;
	}
	run() {
		this.fun.apply(null, this.args);
	}
}

function cleanUpNextTick() {
	if (!isDraining || !currentQueue) {
		return;
	}
	isDraining = false;
	if (currentQueue.length > 0) {
		queue = [...currentQueue, ...queue];
	} else {
		queueIndex = -1;
	}
	if (queue.length > 0) {
		drainQueue();
	}
}

function drainQueue() {
	if (isDraining) {
		return;
	}
	const timeout = setTimeout(cleanUpNextTick, 0);
	isDraining = true;

	let len = queue.length;
	while (len) {
		currentQueue = queue;
		queue = [];
		while (++queueIndex < len) {
			if (currentQueue) {
				currentQueue[queueIndex].run();
			}
		}
		queueIndex = -1;
		len = queue.length;
	}
	currentQueue = null;
	isDraining = false;
	clearTimeout(timeout);
}

process.nextTick = (fun: (...args: unknown[]) => unknown, ...args: unknown[]): void => {
	queue.push(new Item(fun, args));
	if (queue.length === 1 && !isDraining) {
		setTimeout(drainQueue, 0);
	}
};

//#endregion
